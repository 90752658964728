@import 'styles/variables.scss';

.Layout {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  min-height: 100vh;

  overflow: auto;

  display: grid;

  &--gradient {
    background: $stagepilot-teal;
  }

  &--logged-in {
    min-width: $content-width + $sidebar-width;

    grid-template-rows: $header-height 1fr;
    grid-template-columns: $sidebar-width minmax($content-width, 1fr);
    grid-template-areas:
      'side head'
      'side main';
  }
}

.HeaderArea,
.NotificationsArea {
  grid-area: head;

  position: relative;
}

.SidebarArea {
  grid-area: side;
}

.ContentArea {
  grid-area: main;

  position: relative;
}
