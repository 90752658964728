.GuestSearch {
  display: flex;
  flex-wrap: wrap;
  margin-top: 12px;

  &__Input,
  &__Select {
    flex: 0 0 50%;
    max-width: 50%;
  }

  &__Input {
    padding-right: 12px;
  }

  @media (min-width: 576px) {
    &__Input {
      flex: 0 0 66.66667%;
      max-width: 66.66667%;
    }
    &__Select {
      flex: 0 0 33.33333%;
      max-width: 33.33333%;
    }
  }

  &__Empty {
    text-align: center;
  }

  .Input__Control {
    line-height: 36px;
  }
}
