@import 'styles/variables.scss';

.DashboardChart {
  .DashboardCard__Content {
    padding: 30px 0 12px;
  }

  .recharts-responsive-container {
    --left-offset: 20px;

    width: calc(100% + var(--left-offset)) !important;
    margin-left: calc(-1 * var(--left-offset));
  }

  .recharts-surface {
    overflow: visible;
  }

  .recharts-cartesian-grid-horizontal {
    stroke-dasharray: 0;
    color: $gray-20;
  }

  .recharts-cartesian-grid-vertical {
    stroke-dasharray: 3;
    color: $gray-20;
    opacity: 0.75;
  }

  .recharts-cartesian-axis-tick {
    font-size: 10px;
  }

  .DashboardEmptyCard--chart {
    position: absolute;

    top: 19px;
    bottom: 40px;
    left: 39px;
    right: 0;
  }
}
