@import 'styles/variables.scss';

.AdminAlerts {
  display: inline-block;
  position: relative;

  &__List {
    position: absolute;
    left: 0;
    top: 68px;
    width: 300px;
    max-height: 70vh;
    overflow: auto;
    z-index: 1;

    background: $white;
    border: 1px solid $gray-30;
    border-radius: 3px;
    box-shadow: 0 4px 11px hsla(0, 0%, 0%, 0.1);
  }

  &__Empty {
    text-align: center;
  }

  .IconButton {
    border: 1px solid $white;
  }

  &--open {
    .IconButton {
      border-color: $gray-30;
      background: $gray-10;
    }
  }
}

.AdminAlert {
  background: $white;
  padding: 10px 15px;
  font-size: 14px;
  line-height: 18px;
  border-bottom: 1px solid $gray-20;

  &__Timestamp {
    font-size: 12px;
    line-height: 1;
    opacity: 0.7;

    margin: 5px 0 0;
  }

  &--read {
    background: $gray-10;
    color: $gray-60;
  }
}
