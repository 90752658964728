@import 'styles/variables.scss';

.DashboardLoadingCard {
  margin-top: 10px;
  min-height: 200px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background: $gray-0;

  &__Message {
    font-size: 10px;
    line-height: 12px;
    color: $gray-70;
    text-align: center;
  }
}
