@import 'styles/variables.scss';

.Header {
  background-color: $white;
  color: $text-color;

  height: 100%;
  line-height: $header-height;
  padding: 0 20px;

  &__Impersonating {
    display: inline-block;
    background-color: $stagepilot-red;
    color: $white;
    line-height: 24px;
    padding: 3px 12px;
    border-radius: 6px;
  }

  &__Controls > * {
    margin-right: 12px;
  }

  &__Picker {
    position: absolute;
    right: 30px;
    top: 23px;

    display: inline-block;
    line-height: 24px;
    width: 300px;
    margin-left: $sidebar-width;
  }

  &__EventPicker {
    &__control,
    &__single-value {
      color: $text-color;
    }

    &__menu-list {
      color: $text-color;
    }
  }
}
