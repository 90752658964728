.ButtonPanel {
  text-align: left;
  padding: 0;

  .Button {
    margin: 0 12px;

    &:first-child {
      margin-left: 0;
    }
  }
}

.ButtonPanel--left {
  text-align: left;
}

.ButtonPanel--center {
  text-align: center;
}

.ButtonPanel--right {
  text-align: right;
}
