@import 'styles/variables.scss';

.DashboardReport {
  padding-left: 0;
  padding-right: 0;

  .DashboardCard__Title {
    padding-left: 15px;
  }

  .DashboardCard__Date {
    padding-right: 15px;
  }

  &__Value {
    text-align: right;
  }

  &__Table {
    width: 100%;
    border-collapse: collapse;
    margin: 16px 0 18px;
  }

  &__Row {
    line-height: 32px;
    color: $gray-60;

    td {
      padding: 0 15px;
    }

    &:nth-child(odd) {
      background-color: $gray-0;
    }
  }
}
