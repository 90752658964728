* {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  width: 100%;
  overflow: auto;
}

body {
  margin: 0;
  padding: 0;

  background-color: $white;
  color: $text-color;

  font-size: 14px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

h1 {
  font-weight: normal;
  font-size: 21px;
  line-height: 30px;
  margin: 0 0 12px;
}

h1 a {
  text-decoration: none;
  color: inherit;
}

h1 a:hover {
  text-decoration: underline;
}

p {
  margin: 6px 0;
}

input:focus,
button:focus,
select:focus {
  outline: none;
}

p,
td,
th,
input,
select {
  font-size: 14px;
}

a,
a:visited {
  color: $stagepilot-teal;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

a:active {
  background-color: $stagepilot-orange;
  color: $text-color;
}

button {
  cursor: pointer;
}
