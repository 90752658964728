@import 'styles/variables.scss';

.Note {
  margin-bottom: 1.5rem;
}

.Note__Header {
  font-size: 14px;
  padding: 0.5rem 0.25rem;
}

.Note__User {
  color: $stagepilot-teal;
}

.Note__Time {
  color: $gray-50;
}

.Note__Content {
  border: 1px solid $gray-20;
  padding: 1rem;
  line-height: 1.5rem;
}
