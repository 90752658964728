@import 'styles/variables.scss';

.UploaderOverview {
  &__Header {
    border-bottom: solid 2px $gray-20;
    padding: 0 12px;
    display: inline-block;
    line-height: 36px;
    font-weight: 500;
    white-space: nowrap;
  }

  &__Cell {
    position: absolute;
    top: 0;
    bottom: 0;
    padding: 0 12px;
    display: inline-block;
    line-height: 36px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__Progress {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: $gray-0;
    line-height: 36px;
  }

  &__List {
    width: 100%;
  }

  &__Row {
    position: relative;
    height: 36px;
    margin: 6px 0;

    &:first-child {
      margin-bottom: 9px;
    }
  }

  &__Date {
    width: 20%;
    left: 0;
  }

  &__Location {
    width: 30%;
    left: 20%;
  }

  &__LastModified {
    width: 20%;
    left: 50%;
  }

  &__Photos {
    width: 15%;
    left: 70%;
  }

  &__Fans {
    width: 15%;
    left: 85%;
  }

  &__Badge {
    display: inline-block;
    margin: 7px 0;

    border-radius: 10px;
    padding: 3px 10px;
    line-height: 1;

    background: $gray-30;
    color: $white;

    &--yesterday {
      background: $stagepilot-orange;
    }

    &--old {
      background: $stagepilot-red;
    }
  }
}
