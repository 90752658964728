$stagepilot-green: #50e3c2;
$green-tint: #b9f8da;
$stagepilot-orange: #ffc144;
$orange-tint: #fdefa6;
$stagepilot-red: #fa7268;
$red-tint: #ffc5c0;
$stagepilot-teal: #24bbec;
$stagepilot-black: #000000;
$teal-tint: #b2eef8;

$white: #ffffff;
$gray-0: #f9f9f9;
$gray-10: #f3f4f4;
$gray-20: #e1e5e6;
$gray-30: #c3c9ca;
$gray-40: #939ea0;
$gray-50: #727d7f;
$gray-60: #5b6466;
$gray-70: #444b4c;
$gray-80: #2e3233;

$text-color: $gray-70;

$modal-tint: rgba($gray-70, 0.79);

$button-primary: $stagepilot-teal;
$button-primary-hover: $stagepilot-teal;
$button-primary-active: $stagepilot-teal;

$button-danger: $stagepilot-red;
$button-danger-hover: lighten($stagepilot-red, 2%); // TODO: get proper shades
$button-danger-active: darken($stagepilot-red, 2%); // TODO: get proper shades

$box-shadow: 0 2px 4px $gray-80;
$light-box-shadow: 0 2px 4px $gray-20;
$box-border-radius: 3px;

$control-border-radius: 6px;

$sidebar-width: 198px;
$header-height: 84px;
$header-content-height: 72px;

$content-width: 570px;

$caption-width: 130px;
