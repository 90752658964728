@import 'styles/variables.scss';

.HourlyTable .DashboardCard__Content {
  display: flex;
  flex-direction: row;
  padding: 1rem 0;
  text-align: center;
  text-transform: uppercase;
}

.HourlyTable__day,
.HourlyTable__HourNames {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-basis: 0;
}

.HourlyTable__hour,
.HourlyTable__HourName,
.HourlyTable__dayName {
  height: 30px;
  margin: 0.2rem;
  font-size: 10px;
}

.HourlyTable__HourName {
  white-space: nowrap;
}

.HourlyTable__ratio5 {
  background-color: #2b7289;
}

.HourlyTable__ratio4 {
  background-color: #24bbec;
}

.HourlyTable__ratio3 {
  background-color: #a3e0f3;
}

.HourlyTable__ratio2 {
  background-color: #ddf3fa;
}

.HourlyTable__ratio1 {
  background-color: #f9f9f9;
}
