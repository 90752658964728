@import 'styles/variables.scss';

.TicketPurchasePage__Header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.TicketPurchasePage__BodySectionHeader {
  font-weight: normal;
  font-size: 14px;
  color: $gray-50;
  text-transform: uppercase;
}

.TicketPurchasePage__SubSectionHeader {
  font-weight: normal;
  font-size: 12px;
  color: $gray-50;
}

.TicketPurchasePage__PurchaseDetails .Separator {
  margin: 2rem 0;
}

.PurchaseDetails__Label {
  font-weight: bold;
  margin-right: 1rem;
}

.TicketPurchasePage__MerchTable {
  th {
    padding: 0px 25px;
  }

  &_Name {
    width: 150px;
  }

  &_Cell {
    text-align: center;
  }
}
