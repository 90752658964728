@import 'styles/variables.scss';

.Table__Header a,
.Table__Header a:visited,
.Table__Header a:hover,
.Table__Header a:active {
  color: $text-color;
  background-color: transparent;
  text-decoration: none;
  font-weight: 500;
}

.Table__SortIndicator {
  position: absolute;
  margin-left: 3px;
  font-size: 80%;
}
