@import 'styles/variables.scss';

.PhotographerSignupForm {
  margin: 0 auto;

  .SignupStep--disabled {
    pointer-events: none;
    color: $gray-30;

    a {
      opacity: 0.5;
    }
  }

  .UploadPassword .Button {
    margin-left: 6px;
    line-height: 30px;
  }

  .Separator {
    margin: 0 -32px 27px;
    border-top: solid 3px $gray-0;
  }
}

.Layout--logged-in .PhotographerSignupForm {
  margin: 0;
  padding-top: 9px;

  max-width: 100%;

  .Form {
    max-width: 480px;
  }
}
