@import 'styles/variables.scss';

.Activity {
  margin-bottom: 1.5rem;
}

.Activity__Header {
  font-size: 14px;
  padding: 0.5rem 0.25rem;
}

.Activity__PerformedBy {
  color: $stagepilot-teal;
}

.Activity__Time {
  color: $gray-50;
}
