@import 'styles/variables.scss';

.TicketPackage,
.TicketPackage__Info {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.TicketPackage {
  justify-content: space-between;
  background-color: $gray-0;
  margin: 1rem 0;
  padding: 1rem;
}

.TicketPackage__Color {
  width: 60px;
  height: 40px;
  border: 1px solid $gray-10;
  margin-right: 1rem;
  border-radius: 5px;
}
