@import 'styles/variables.scss';

.IconButton {
  border: 0;
  font-size: 12px;
  line-height: 36px;
  position: relative;
  padding: 0 12px 0 36px;
  border-radius: 18px;
  color: $text-color;
  background-color: transparent;

  &__Icon {
    position: absolute;
    top: 6px;
    left: 6px;
    width: 24px;
    height: 24px;
  }

  &--highlight {
    color: $stagepilot-red;

    g {
      stroke: $stagepilot-red;
    }
  }

  &:disabled {
    opacity: 0.5;
  }

  &:hover {
    background-color: $gray-10;
    text-decoration: none;
  }

  &:active {
    background-color: $gray-50;
    color: $white;

    g {
      stroke: $white;
    }
  }
}
