@import 'styles/variables.scss';

.TicketPurchases {
  margin-bottom: 100px;
}

.ImportTicketPurchasesModal__ButtonPanel {
  position: absolute;
  position: absolute;
  right: 36px;
  bottom: 0;
  text-align: right;
  width: 100%;
  padding: 1rem 0;
  background-color: white;
}

.Table .ImportPreviewRow td:first-child {
  border-radius: 6px 0 0 6px;
}

.Table .ImportPreviewRow td:last-child {
  border-radius: 0 6px 6px 0;
}

.Table .ImportPreviewRow--added td {
  background-color: $green-tint;
}

.Table .ImportPreviewRow--deleted td {
  background-color: $red-tint;
}

.Table .ImportPreviewRow--changed td {
  background-color: transparent;
}

.Table td.ImportPreviewItem--changed {
  background-color: $orange-tint;
}

.NewPackagesWarning .Modal__Window {
  width: 400px;
}

.HeaderMappings {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;
  overflow-x: scroll;
}

.HeaderMapping {
  flex: 0 0 auto;
  width: 220px;
  text-align: center;
  margin-right: 12px;
}

.HeaderMapping.HeaderMapping--isNew {
  border-color: $stagepilot-orange;
}

.HeaderMapping__Header {
  visibility: hidden;
  padding: 0.5rem;
}

.HeaderMapping--isNew .HeaderMapping__Header {
  visibility: visible;
  background-color: $stagepilot-orange;
  color: white;
}

.HeaderMapping__Body {
  padding: 1rem;
  border: 1px solid $stagepilot-teal;
  background-color: #f5fdfe;
  min-height: 330px;
}

.HeaderMapping--isNew .HeaderMapping__Body {
  border-color: $stagepilot-orange;
  background-color: #fff;
}

.HeaderMapping__Name {
  margin-bottom: 1rem;
}

.HeaderMapping__ButtonPanel {
  margin: 2rem 0;
}

.HeaderMapping__Examples {
  margin-top: 1.5rem;
}
.HeaderMapping__Example {
  padding: 1rem 0;
  border-top: 1px solid $gray-10;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
