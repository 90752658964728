.Tabs {
  display: flex;
  font-size: 14px;
  width: 100%;
  line-height: 32px;

  .Tab {
    padding: 0 9px;
    margin: 0 0 0 9px;
    line-height: 32px;
    color: $gray-70;
    text-decoration: none;
    border-radius: 6px;
    cursor: pointer;

    &:active {
      background-color: $teal-tint;
    }

    &--active {
      background-color: $teal-tint;
    }
  }
}

.Tabs__Content {
  > .Form {
    margin-top: 0;
  }
}
