@import 'styles/variables.scss';

.DashboardCard {
  width: 100%;
  padding: 12px 15px;

  border-radius: 6px;
  border: solid 1px $gray-20;
  box-shadow: 0px 2px 4px 0 rgba($gray-20, 0.8);

  background-color: white;
  transition: opacity 0.1s;

  &--loading {
    opacity: 0.5;
  }

  &__Title {
    font-size: 12px;
    margin: 0;
    font-weight: 500;
    line-height: 18px;
  }

  &__Date {
    font-size: 10px;
    margin: 0;
    font-weight: normal;
    line-height: 18px;
    color: $gray-50;

    float: right;
  }

  &__Total {
    font-size: 16px;
    margin: 0;
    font-weight: 500;
  }

  &__Content {
    position: relative;
    min-height: 200px;
    height: calc(100% - 20px);
  }
}
